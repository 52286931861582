import Spreadsheet from 'react-spreadsheet'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import queryString from 'query-string'
import './index.css'
import { InputGroup, Button, Form, Stack, Modal } from 'react-bootstrap'

const App = props => {
  let [data, setData] = useState([
    [
      { value: '', className: 'w300' },
      { value: '', className: 'w200' },
      { value: '', className: 'w300' },
      { value: '', className: 'w200' },
      { value: '' }
    ]
  ])
  let [isDisableSave, setIsDisableSave] = useState(false)
  let [dealer, setDealer] = useState('')
  let [maxRow, setMaxRow] = useState(1)
  let [mode, setMode] = useState("")
  let [isShowModalAdd, setIsShowModalAdd] = useState(false)
  let [isShowModalAction, setIsShowModalAction] = useState(false)
  let [dataAction, setDataAction] = useState([])
  let [forwarder, setForwarder] = useState([])
  useEffect(() => {
    initData()
  }, [dealer, maxRow])
  async function initData() {
    console.log(window.location)

    let params = queryString.parse(window.location.search)
    setMode(params?.mode ?? "")
    setDealer(params.dealer_id)
    let result = await axios.get('https://ea.autoservice.ai/api/api', {
      params: {
        dealer: params.dealer_id
      }
    })
    const forward = await axios.get("https://ea.autoservice.ai/api/forward")
    setForwarder(forward.data)
    setMaxRow(parseInt(params.max))
    result = result.data.map(element => {
      const array = []
      array.push({ value: element.name, readOnly: true, className: 'w300' })
      array.push({
        value: element.extension,
        readOnly: true,
        className: 'w200'
      })
      array.push({
        value: element.phone_number,
        readOnly: true,
        className: 'w200'
      })
      array.push({ value: element.email, readOnly: true, className: 'w300' })
      array.push({ value: element.EA, readOnly: true, className: 'w200' })
      array.push({ value: element.status, readOnly: true })
      array.push({ value: element?.optout ? "YES" : "NO", readOnly: true })
      array.push({ value: element?.configEscalationOnOff ? "YES" : "NO", readOnly: true })
      array.push({ value: convertBuddyList(element?.buddy_list), readOnly: true })
      array.push({ value: convertAlertEmail(element.alert_email), readOnly: true })
      return array
    }).sort((a, b) => a[0]?.value?.trim()?.charAt(0) > b[0]?.value?.trim()?.charAt(0) ? 1 : -1)
    let emptyArray = []
    for (let i = parseInt(params.max) - result.length; i >= 1; i--) {
      emptyArray.push([
        { value: '', className: 'w300' },
        { value: '', className: 'w200' },
        { value: '', className: 'w200' },
        { value: '', className: 'w300' },
        { value: '', className: 'w200' },
        { value: '' }
      ])
    }
    setData([...result, ...emptyArray])
    setTimeout(() => {
      const listRow = document.getElementsByTagName("tr")
      console.log(result.length)
      for (let i = 0; i < listRow.length; i++) {
        const element = listRow[i]
        const column = Array.prototype.slice.call(element.getElementsByTagName("td"));
        if (column.length === 10 && i <= result.length && mode === "change") {
          const td = document.createElement("td")
          const button = document.createElement("button")
          button.className = "btn btn-primary"
          button.addEventListener("click", () => {
            if (window.confirm(`Delete EA: ${result[i - 1][0].value}`) === true) {
              axios.delete('https://ea.autoservice.ai/api/delete', {
                params: {
                  ea: result[i - 1][4].value,
                  advisor: result[i - 1][2].value,
                }
              }).then(() => {
                initData()
              })
            }
            // showModalAction()
            // setDataAction(result[i-1])
          })
          button.textContent = "Delete"
          td.appendChild(button)
          element.append(td)
        }
      }
    }, 1000)
  }
  function convertBuddyList(buddy_list) {
    buddy_list = JSON.parse(buddy_list)
    // if (buddy_list?.[0]?.email) {
    //   const email = forwarder.find(e => e.mail_group === buddy_list?.[0]?.email)
    //   return email?.mail ?? ""
    // }
    const result = `${buddy_list?.[0]?.email?.replace("@allio.io", "")} ${forwarder.filter(e => e.mail_group.toLowerCase() === buddy_list?.[0]?.email.toLowerCase()).map(e => e.mail).join(',')}`
    return buddy_list?.[0]?.email?.replace("@allio.io", "") ?? ""
  }
  function convertAlertEmail(alert) {
    // if (alert) {
    //   const email = forwarder.find(e => e.mail_group === alert)
    //   return email?.mail ?? ""
    // }
    const result = `${alert?.replace("@allio.io", "")} ${forwarder.filter(e => e.mail_group.toLowerCase() === alert.toLowerCase()).map(e => e.mail).join(',')}`

    return alert?.replace("@allio.io", "") ?? ""
  }
  async function handleSave() {
    let result = data.filter(element => !element[0].readOnly)
    console.log(result)
    setIsDisableSave(true)
    for (let i = 0; i < result.length; i++) {
      const agent = result[i]
      if (!agent[0].value || !agent[2].value || !agent[3].value) {
        continue
      }
      try {
        await axios.post(
          'https://ea.autoservice.ai/api/add',
          {
            agentName: agent[0].value,
            extension: agent[1].value,
            agentId: agent[2].value?.replace("+1", ""),
            agentEmail: agent[3].value,
            dealer: dealer
          },
          {
            headers: {
              'content-type': 'application/json'
            }
          }
        )
      } catch (e) {
        console.log(e)
      }
    }
    await initData()
    setIsDisableSave(false)
  }
  function onChangeCell(prevCell, nextCell, coords) {
    // let result = []
    // console.log("----");
    // console.log(datax.length > maxRow)
    // console.log(datax?.[0]?.length > 6);
    // if (datax.length > maxRow || datax?.[0]?.length > 6) {
    //   datax.forEach((element, index) => {
    //     result[index] = element.slice(0, 5)
    //   })
    //   setData(result.slice(0, maxRow))
    //   console.log('aa')
    // }else if (datax !== data) {
    //   setData(datax)
    // }

    // if (coords) {
    //   let result = [...data]
    //   result[coords.row] = [...result[coords.row]]
    //   result[coords.row][coords.column] = nextCell
    //   setData(result)
    // }
  }
  function showModalAdd() {
    setIsShowModalAdd(!isShowModalAdd)
  }
  function showModalAction() {
    setIsShowModalAction(!isShowModalAction)
  }
  const handleFormAdd = async (e) => {
    e.preventDefault()
    const advisorName = document.getElementById("advisorName").value
    const extension = document.getElementById("extension").value
    const advisorPhone = document.getElementById("advisorPhone").value
    const advisorEmail = document.getElementById("advisorEmail").value

    if (!advisorName || !advisorPhone || !advisorEmail) {
      setIsShowModalAdd(false)
      return;
    }
    try {
      await axios.post(
        'https://ea.autoservice.ai/api/add',
        {
          agentName: advisorName,
          extension: extension,
          agentId: advisorPhone?.replace("+1", ""),
          agentEmail: advisorEmail,
          dealer: dealer
        },
        {
          headers: {
            'content-type': 'application/json'
          }
        }
      )
    } catch (e) {
      console.log(e)
    }
    await initData()
    setIsShowModalAdd(false)
    setIsDisableSave(false)
  }
  return (
    <div className='p20'>
      <h5>Executive Assistants</h5>
      <Extension />
      {["change", "add"].includes(mode) ? <Button className='mb-3' onClick={showModalAdd}>Add</Button> : ""}
      <Spreadsheet
        onCellCommit={onChangeCell}
        data={data}
        columnLabels={[
          'Advisor Name ',
          'Extension',
          'Advisor Phone Number',
          'Advisor Email',
          'EA',
          'Status',
          'Do No Sent SMS',
          'Escalation On/Off',
          'Escalation 2',
          'Escalation 3'
        ]}
      />
      <br />
      <Button variant='secondary' disabled={isDisableSave} onClick={handleSave}>
        Save
      </Button>
      <Modal
        show={isShowModalAdd}
        onHide={showModalAdd}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add new EA</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleFormAdd}>
          <Modal.Body>

            <Form.Label htmlFor="advisorName">Advisor Name</Form.Label>
            <Form.Control
              type="text"
              id="advisorName"
              required
            />
            <Form.Label htmlFor="extension">Extension</Form.Label>
            <Form.Control
              type="text"
              id="extension"

            />
            <Form.Label htmlFor="advisorPhone">Advisor Phone</Form.Label>
            <Form.Control
              type="text"
              id="advisorPhone"
              required
            />
            <Form.Label htmlFor="advisorEmail">Advisor Email</Form.Label>
            <Form.Control
              type="email"
              id="advisorEmail"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter email.
            </Form.Control.Feedback>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={showModalAdd}>
              Close
            </Button>
            <Button type="submit" variant="primary">Add</Button>
          </Modal.Footer>
        </Form>
      </Modal>



      <Modal
        show={isShowModalAction}
        onHide={showModalAction}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Action for {dataAction[0]?.value} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label htmlFor="extension">Extension</Form.Label>
          <Form.Control
            type="text"
            id="extension"
            value={dataAction[1]?.value}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={showModalAction}>
            Close
          </Button>
          <Button onClick={handleFormAdd} variant="primary">Change</Button>
          <Button onClick={handleFormAdd} variant="primary">Remove</Button>
          <Button onClick={handleFormAdd} variant="primary">Opt</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}



const Extension = () => {
  let [emailIT, setEmailIT] = useState("")
  let [emailManager, setEmailManager] = useState("")

  function onChangeIT(value) {
    setEmailIT(value.target.value)
  }
  function onChangeManager(value) {
    setEmailManager(value.target.value)
  }
  async function onSendMail(depart) {
    console.log(depart);
    let params = queryString.parse(window.location.search).dealer_id
    if (depart === "it") {
      axios.get(`https://ea.autoservice.ai/api/email?email=${emailIT}&department=it&dealer=${params}`)
    } else {
      axios.get(`https://ea.autoservice.ai/api/email?email=${emailManager}&department=manager&dealer=${params}`)
    }
  }
  return (
    <div>
      <Stack direction='horizontal' gap={2}>
        <InputGroup size='sm' className='mb-3'>
          <Button variant='outline-secondary' id='button-addon1' onClick={() => onSendMail("it")}>
            Send Mail To IT
          </Button>
          <Form.Control
            value={emailIT}
            onChange={onChangeIT}
            placeholder='Input Email IT'
            aria-label='Example text with button addon'
            aria-describedby='basic-addon1'
          />
        </InputGroup>{' '}
        <InputGroup size='sm' className='mb-3' onClick={() => onSendMail("manager")}>
          <Button variant='outline-secondary' id='button-addon1'>
            Send Mail To Manager
          </Button>
          <Form.Control
            value={emailManager}
            onChange={onChangeManager}
            placeholder='Input Email Manager'
            aria-label='Example text with button addon'
            aria-describedby='basic-addon1'
          />
        </InputGroup>
      </Stack>
    </div>
  )
}

export default App
